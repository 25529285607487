const pageList = [
    {name:'Dashboard', url:'/dashboard'},
    // {name:'OTP Verify', url:'/verifyOTP'},
    {name:'Contact us', url:'/contact-us'},
    {name:'Notification', url:'/notification'},
    {name:'Manage Store', url:'/store'},
    {name:'Categories', url:'/categories'},
    {name:'Product', url:'/product'},
    {name:'Products List', url:'/product/list'},
    {name:'Bulk Product', url:'/bulkUpload'},
    {name:'Product Catalogue', url:'/product/catalouge'},
    {name:'Products Catalouge List', url:'/product/catalogue/list'},
    {name:"Product's Inquiry List", url:'/product/inquiry'},
    {name:'Invite Customers', url:'/invite-customer'},
    {name:'Pending Customers', url:'/pending-customer'},
    {name:'Active Customers', url:'/active-customer'},
    {name:'New Customer Requests', url:'/customer-request'},
    {name:'User List', url:'/user-list'},
    {name:'Reports', url:'/chart'},
    {name:'Raise Ticket', url:'/help'},
    {name:"Faq's", url:'/faq'},
    {name:'Profile', url:'/profile'},
    {name:'Manage Subscription', url:'/active-subcription'},
    {name:'About', url:'/about-us'},
    {name:'Terms & Conditions', url:'/terms-conditions'},
    {name:'Privacy Policy', url:'/privacy-policy'},
    {name:'Quick Tutorials', url:'/quick-tutorial'},
    {name:'Customer Profile', url:'/customers/view'},
    {name:'Vendor Profile', url:'/store/view'},
    {name:'Store Product List', url:'/store/product/list'},
    {name:'Store Product View', url:'/store/product/view'},
    {name:'Store Product Catalogue', url:'/store/product/catalogue'},
]

export default pageList;