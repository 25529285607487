import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/theme';

export default function NotFound() {
  const { auth } = useContext(UserContext);
  const navigate = useNavigate()
  const [isNotFound, setIsNotFound] = useState(false)
  useEffect(() => {
    // if(auth){
    //   navigate('/dashboard')
    // }else{
    //   navigate('/')
    // }
    setIsNotFound(false)
    setTimeout(()=>{
      setIsNotFound(true)
    },2000)
  }, [auth]);
  return (
    <div className='notFoundWrp'>
      {isNotFound? (
        <img src='/assets/images/404.webp' className='img-fluid' alt='404' />
      ):(
        'Loading...'
      )}
    </div>
  )
}
